<template>
  <b-card class="user-transport">
    <b-row class="show-tabs-list-mobile">
      <b-col cols="12">
        <b-tabs
          pills
          class="nav-tabs-center"
        >
          <b-tab active>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('My Trips') }}</span>
            </template>
            <good-data-table
              :columns="myTripColumns"
              :total-column="'response.responseData.transport.total'"
              :data-column="'response.responseData.transport.data'"
              :api-endpoint="'/user/trips-history/transport'"
              :extra-params="'type=history'"
              :default-sort-params="{
                field: 'created_time',
                type: 'desc',
              }"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Upcoming Trips') }}</span>
            </template>
            <good-data-table
              :columns="upcomingTripColumn"
              :total-column="'response.responseData.transport.total'"
              :data-column="'response.responseData.transport.data'"
              :api-endpoint="'/user/trips-history/transport'"
              :extra-params="'type=upcoming'"
              :default-sort-params="{
                field: 'created_time',
                type: 'desc',
              }"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-modal
      id="viewRideDetails"
      v-model="transportInfo.showRideDetails"
      :title="$t('Ride Details')"
      :no-close-on-backdrop="true"
      :hide-footer="true"
      size="lg"
      @hidden="hideRideDetailsModal"
    >
      <b-row v-if="transportInfo.rideHistory">
        <b-col
          md="6"
          class="border p-1"
        >
          <p
            class="py-2 text-center font-weight-bold font-18 text-white"
            style="background-color: #7367f0;"
          >
            {{ transportInfo.rideHistory.ride ? transportInfo.rideHistory.ride.vehicle_name : '' }} - {{ $t('FARE BREAKDOWN') }}
          </p>
          <p class="mb-50">
            <span class="font-weight-bold">{{ $t('From') }}: </span>{{ transportInfo.rideHistory.s_address }}
          </p>
          <p class="mb-50">
            <span class="font-weight-bold">{{ $t('To') }}: </span>{{ transportInfo.rideHistory.d_address }}
          </p>
        </b-col>
        <b-col
          md="6"
          class="border p-1 border-left-0"
        >
          <img
            class="w-100 h-100"
            :src="$helpers.imageHelper(transportInfo.rideHistory.static_map)"
            @error="$helpers.imageError"
          >
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BTab, BTabs, BCard, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { useUserUi } from './useUser'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    BTab,
    BTabs,
    BCard,
    BModal,
  },
  data() {
    return {
      myTripColumns: [
        {
          label: 'Booking Id',
          field: 'booking_id',
        },
        {
          label: 'Date',
          field: 'created_time',
        },
        {
          label: 'Name',
          field: 'first_name',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.provider) {
              return `${props.provider.first_name} ${props.provider.last_name}`
            }
            return 'NA'
          },
        },
        {
          label: 'Total Amount',
          field: 'total_payment',
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return `${props.user.currency_symbol} ${props.payment.total}`
            }
            return 'NA'
          },
        },
        {
          label: 'Vehicle Name',
          field: 'ride.vehicle_name',
        },
        {
          label: 'Payment',
          field: 'payment_mode',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return props.payment.payment_mode
            }
            return 'NA'
          },
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewMyTripsDetails(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('dispute'),
              text: 'Dispute',
              action: props => {
                this.createMyTripsDispute(props)
              },
            },
          ],
        },
      ],
      upcomingTripColumn: [
        {
          label: 'Booking Id',
          field: 'booking_id',
        },
        {
          label: 'Scheduled Time',
          field: 'schedule_time',
        },
        {
          label: 'Total Amount',
          field: 'provider_id',
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return `${props.user.currency_symbol} ${props.payment.total}`
            }
            return 'NA'
          },
        },
        {
          label: 'Vehicle Name',
          field: 'ride.vehicle_name',
        },
        {
          label: 'Payment',
          field: 'provider_id',
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return props.payment.payment_mode
            }
            return 'NA'
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewUpcomingTripsDetails(props)
              },
            },
          ],
        },
      ],
      transportInfo: {
        showRideDetails: false,
        rideHistory: null,
      },
    }
  },
  methods: {
    hideRideDetailsModal() {
      this.transportInfo.showRideDetails = false
    },
    viewMyTripsDetails(props) {
      this.getTransportTripHistory(props)
    },
    getTransportTripHistory(props) {
      const { userTripHistory } = useUserUi()

      showLoader()
      userTripHistory(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.transportInfo.rideHistory = data.responseData.transport
            this.transportInfo.showRideDetails = true
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    createMyTripsDispute(props) {
      console.log(props)
    },
    viewUpcomingTripsDetails(props) {
      console.log(props)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
